import type { Entity } from '@revolutionprep/types'
/**
 * Checks whether or not the given entity or object is empty
 *
 * @param {Entity || Object} object object
 * @returns {boolean}
 */

export default function (object?: Entity<unknown> | Object | null): boolean {
  if (!object) {
    return true
  }
  return !Object.keys(object || {}).length
}
